<template>
  <div>
    <v-app class="login-wrapper">
      <v-main>
        <v-container fill-height>
          <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4 lg4>
              <v-card class="elevation-1 pa-2 ma-12">
                <v-card-text class="pb-0">
                  <div class="layout column align-center">
                    <v-container>
                      <v-row no-gutters align="center">
                        <v-col cols="12" sm="12" class="app-title">
                          <img
                            src="../assets/logo.png"
                            alt="logo"
                            class="logo md-title"
                          />
                          mdb
                        </v-col>
                        <v-col
                          cols="10"
                          sm="10"
                          md="6"
                          offset-md="3"
                          class="pa-1"
                        >
                        </v-col>
                        <v-col align="center" cols="12" mr="6" sm="12">
                          Медицинская База Данных
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                  <v-form autocomplete="off">
                    <v-text-field
                      v-model="user"
                      name="user"
                      label="Пользователь"
                      type="text"
                      outlined
                      :error="error"
                      :roles="[rules.required]"
                    />
                    <v-text-field
                      id="password"
                      v-model="password"
                      name="password"
                      label="Пароль"
                      hide-details
                      class="mb-0"
                      outlined
                      :error="error"
                      :roles="[rules.required]"
                      :type="hidePassword ? 'password' : 'text'"
                      :append-icon="
                        hidePassword ? 'mdi-eye-off-outline' : 'mdi-eye'
                      "
                      @keyup.enter="login"
                      @click:append="hidePassword = !hidePassword"
                    />
                    <v-row
                      no-gutters
                      class="align-center pa-0 ma-0"
                      style="min-height: 2rem"
                    >
                      <v-col cols="2" sm="6" md="6">
                        <!-- TODO реализовать логику кнопки "Запомнить меня"-->
                        <!--                        <v-checkbox-->
                        <!--                          label="Запомнить меня"-->
                        <!--                          class="v-size&#45;&#45;x-small"-->
                        <!--                          disabled-->
                        <!--                        />-->
                      </v-col>
                      <v-col cols="2" sm="6" md="4" class="ml-auto">
                        <!-- TODO реализовать логику кнопки "Забыли пароль?"-->
                        <!--                        <a disabled="true"><span>Забыли ваш пароль?</span></a>-->
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn block color="primary" :loading="loading" @click="login()"
                    ><span class="white--text">Войти</span></v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
        <v-snackbar v-model="showResult" :timeout="2000" top>
          {{ result }}
        </v-snackbar>
      </v-main>
      <v-footer style="background: none">
        <v-col
          class="white--text text-center"
          cols="12"
          style="font-size: 14px"
        >
          SOS Medical Assistance (с) 2009 - {{ new Date().getFullYear() }}
        </v-col>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
import fetch from "../utils/fetch";
import ctx from "../ctx";
import btoa from "../utils/btoa";

export default {
  name: "Login",
  data() {
    return {
      loading: false,
      user: "",
      password: "",
      valid: true,
      rememberMe: false,
      hidePassword: true,
      error: false,
      showResult: false,
      result: "",
      rules: {
        required: value => !!value || "Поле обязательно для заполнения."
      }
    };
  },
  methods: {
    
    async login() {
      if (!this.user || !this.password) {
        this.result = 'Поля "Пользователь" и "Пароль" должны быть заполнены.';
        this.showResult = true;
      } else {
        const data = btoa(this.user.toLowerCase() + "::" + this.password);
        const res = await fetch.post("/api/auth/login?credentials=" + data);
        if (res) {
          if (res.success) {
            ctx.setAuthFromResponse(res);
          } else {
            this.error = true;
            this.result = res.result;
            this.showResult = true;
          }
        }
      }
    },

    /**
     * octellIntegrationAuth
     */
    async octellIntegrationAuth(operatorID, cardNo) {
      try {
        const response = await fetch.octell(`/api/oktell/secret-key-auth`, operatorID);
        console.log('response --- >', response);
        ctx.setAuthFromOktell(response, cardNo);
      } catch (error) {
        console.log(error);
      } 
    },

    // TODO реализовать страницу "Забыли ваш пароль?"
    // forgot() {this.$router.push('/forgot')}
  },
  watch: {
    getRegisteredStatus(val) {
      if (!val) return;
      if (val === 403) {
        this.result =
          "Срок действия ссесии окончен. \n " +
          "Пожалуйста выполните повторную авторизацию";
        this.showResult = true;
      }
    }
  },
  // mounted() {
  //   const operatorID = this.$route.query.operatorID;
  //   const cardNo =this.$route.query.cardNo;

  //   setTimeout(() => {
  //     if(operatorID && !localStorage.getItem('jwt')) {
  //       this.octellIntegrationAuth(operatorID, cardNo);
  //     }
  //     else if(operatorID && localStorage.getItem('jwt') && cardNo) {
  //       location.replace(location.origin + `/insured?cardNo=${cardNo}`);
  //     }
  //   }, 1000);

  // }
};
</script>

<style lang="scss" scoped>
.app-title {
  color: #66c3d0 !important;
  font-weight: 400;
  font-size: 2em;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    width: 2em;
    margin: 8px;
  }
}
.login-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../assets/mountains_background.png") no-repeat;
  -moz-background-size: 100%; /* Firefox 3.6+ */
  -webkit-background-size: 100%; /* Safari 3.1+ и Chrome 4.0+ */
  -o-background-size: 100%; /* Opera 9.6+ */
  background-size: 100%;
}
</style>
